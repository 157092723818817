import React from 'react';

import Modal from 'components/Common/Modal';

import styles from './SpendInBudgetModal.module.scss';

interface SpendInBudgetModalProps {
  closeModal: () => void;
}

const SpendInBudgetModal = ({ closeModal }: SpendInBudgetModalProps) => (
  <Modal closeModal={closeModal}>
    <div className={styles.mainContainer}>
      <p className={styles.title}>Spend in Budget</p>
      <p>
        Your Plannery Card will help you create a monthly budget. You can change your budget spending limits as needed.
      </p>
      <p className={styles.title}>Earn Points</p>
      <div className={styles.listItemsContainer}>
        <div className={styles.listItem}>
          <span>1.</span> Spend within your budget limits every month.
        </div>
        <div className={styles.listItem}>
          <span>2.</span> Repay your card balance within one billing cycle.
        </div>
        <div className={styles.listItem}>
          <span>3.</span> Earn 2 points per $1 you repay.
        </div>
      </div>
      <p>You’re rewarded for staying out of debt!</p>
    </div>
  </Modal>
);

export default SpendInBudgetModal;
