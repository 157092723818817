import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';
import { getCardData } from 'selectors/getCardData';
import { CurrentOnboardingSteps, setCurrentOnboardingStep } from 'handlers/cardData';

import styles from './Stepper.module.scss';

interface StepData {
  id: CurrentOnboardingSteps;
  name: string;
  route: RoutePath;
}

const stepList: StepData[] = [
  { id: CurrentOnboardingSteps.Rewards, name: 'Rewards', route: RoutePath.CardRewards },
  { id: CurrentOnboardingSteps.SmartSpending, name: 'Smart Spending', route: RoutePath.SmartSpending },
  { id: CurrentOnboardingSteps.SmartAutoPay, name: 'Smart Auto Pay', route: RoutePath.SmartAutoPay },
  { id: CurrentOnboardingSteps.ImportDebt, name: 'Import Debt', route: RoutePath.ImportDebt },
];

const Stepper = ({ currentStep }: { currentStep: number }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationState = useLocation().state as { previousStepId: StepData['id'] };
  const previousStepId = locationState?.previousStepId;
  const { onboardingCompleted } = useSelector(getCardData);
  const isPreviousStepHigher = previousStepId > currentStep;

  const handleOnClick = (activeStep: boolean, step: StepData) => {
    const nextStepAvailable = currentStep > stepList.length ? currentStep + 1 : currentStep;
    const lastStepId: StepData['id'] = stepList[nextStepAvailable].id;
    const hasReachedPreviousStep = previousStepId >= step.id;

    if (activeStep || onboardingCompleted || hasReachedPreviousStep) {
      dispatch(setCurrentOnboardingStep(step.id));
      navigate(step.route, { state: { previousStepId: isPreviousStepHigher ? previousStepId : lastStepId } });
    }
  };

  return (
    <div className={styles.mainContainer}>
      {stepList.map((step, index) => {
        const isStepDisabled =
          index >= currentStep && !onboardingCompleted && (previousStepId < step.id || !isPreviousStepHigher);
        return (
          <div
            key={index}
            className={clsx(
              styles.stepContainer,
              index <= currentStep && styles.activeStepText,
              isStepDisabled && styles.disabledStep,
            )}
            onClick={() => handleOnClick(index < currentStep, step)}
          >
            <span>{step.name}</span>
            <div className={clsx(styles.stepIndicator, index <= currentStep && styles.activeStepBar)} />
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
