import React from 'react';
import { NavigateOptions } from 'react-router-dom';

import { ReactComponent as Chevron } from 'images/chevron.svg';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';

import styles from './ActionItemCard.module.scss';

interface ActionItemCardProps {
  title: string;
  icon: React.FunctionComponent<{ className?: string }>;
  children: JSX.Element;
  route: RoutePath;
  routeOptions?: NavigateOptions;
}

const ActionItemCard = ({ title, icon: Icon, children, route, routeOptions }: ActionItemCardProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => navigate(route, routeOptions)}>
        <Icon className={styles.icon} />
        <p className={styles.title}>{title}</p>
        <Chevron className={styles.arrow} />
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ActionItemCard;
