import { RoutePath } from './Routes';

export const ApplicationStepName: Record<RoutePath, string> = Object.freeze({
  [RoutePath.ExitSurvey]: 'Exit Survey',
  [RoutePath.YourName]: 'Your Name',
  [RoutePath.YourAddress]: 'Your Address',
  [RoutePath.CreatingOffer]: 'Creating Offer',
  [RoutePath.SmsLogin]: 'SMS Login',
  [RoutePath.HardOffer]: 'Hard Offer',
  [RoutePath.PayOffPlan]: 'Pay Off Plan',
  [RoutePath.PlanSent]: 'Plan Sent',
  [RoutePath.LoanAmountRange]: 'Loan Amount Range',
  [RoutePath.CreditScoreRange]: 'Credit Score Range',
  [RoutePath.Location]: 'Location',
  [RoutePath.ConfirmLoan]: 'Confirm Loan',
  [RoutePath.OfferStatus]: 'Offer Status',
  [RoutePath.EmailNotificationAlert]: 'Email Notification Alert',
  [RoutePath.DocuSignSignature]: 'DocuSignSignature',
  [RoutePath.RepaymentMethod]: 'Repayment Method',
  [RoutePath.SetupDeduction]: 'Setup Deduction',
  [RoutePath.SetupViaPayroll]: 'Setup Via Payroll',
  [RoutePath.SetupViaHr]: 'Setup Via HR',
  [RoutePath.VerifyPayrollDeduction]: 'Verify Payroll Deduction',
  [RoutePath.FundsSent]: 'Funds Sent',
  [RoutePath.BankAccount]: 'Bank Account',
  [RoutePath.NextSteps]: 'Next Steps',
  [RoutePath.Plaid]: 'Plaid',
  [RoutePath.PlaidCheckingAccounts]: 'Plaid Checking Accounts',
  [RoutePath.UploadIncomeVerification]: 'Upload Income Verification',
  [RoutePath.OutOfState]: 'Out of State',
  [RoutePath.Offer]: 'Offer',
  [RoutePath.YourBirthDate]: 'BirthDate',
  [RoutePath.SetupAccount]: 'SetupAccount',
  [RoutePath.VerificationSteps]: 'VerificationSteps',
  [RoutePath.Feedback]: 'Feedback',
  [RoutePath.VerifyingApplication]: 'Verifying Application',
  [RoutePath.Alloy]: 'Alloy',
  [RoutePath.YourGoal]: 'YourGoal',
  [RoutePath.OpenDDADone]: 'Open DDA Done',
  [RoutePath.CardApplied]: 'Card Applied',
  [RoutePath.Error]: 'Error',
  [RoutePath.Logout]: 'Logout',
  // Financial Checkup
  [RoutePath.YourPhoneNumber]: 'Your Phone Number',
  [RoutePath.VerifyPhoneNumber]: 'Verify Phone Number',
  [RoutePath.YourEmail]: 'Your Email',
  [RoutePath.VerifyEmail]: 'Verify Email',
  [RoutePath.YourIncome]: 'Your Income',
  [RoutePath.YourSavings]: 'Your Savings',
  [RoutePath.YourFinances]: 'Your Finances',
  [RoutePath.YourSSN4]: 'Your Social Security Number 4',
  [RoutePath.HowItWorks]: 'How It Works',
  [RoutePath.MethodAuth]: 'Method Auth',
  [RoutePath.Analyzing]: 'Analyzing',
  // Card
  [RoutePath.CardInfo]: 'Card Info',
  [RoutePath.CardApplicationSuccessful]: 'Card Application Successful',
  [RoutePath.XSellLoader]: 'Loading',
  [RoutePath.ReferralProgram]: 'Referral Program',
  [RoutePath.ErrorConsolidating]: 'Error Consolidating',
  [RoutePath.ConnectPaycheck]: 'Connect Your Paycheck',
  [RoutePath.ConnectPaycheckFeedback]: 'Connect Paycheck Feedback',
  [RoutePath.BuildCreditAvoidDebtSaveMoney]: 'Card Overview',
  [RoutePath.CardRewards]: 'Card Rewards',
  [RoutePath.SmartSpending]: 'Smart Spending',
  [RoutePath.SmartAutoPay]: 'Smart Auto Pay',
  [RoutePath.ImportDebt]: 'Import Debt',
  [RoutePath.CardSummary]: 'Card Summary',
  [RoutePath.ApplyEmployeeLoan]: 'Apply Employee Loan',
  [RoutePath.ConfirmYourDetails]: 'Confirm Your Details',
  [RoutePath.StayInTouch]: 'Stay In Touch',

  // Missed Payments
  [RoutePath.MissedPayment]: 'Missed Payment',
  [RoutePath.MakeAPayment]: 'Make A Payment',
  [RoutePath.MissedPaymentReason]: 'Missed Payment Reason',
  [RoutePath.Authorization]: 'Authorization',
  [RoutePath.WaitingAuthorization]: 'Waiting Authorization',
  [RoutePath.MakingPayment]: 'Making Payment',
  [RoutePath.PaymentInitiated]: 'Payment Initiated',
  [RoutePath.PaymentFailed]: 'Payment Failed',
  // Student Loan
  [RoutePath.StudentLoanEmployerType]: 'Student Loan Employer Type',
  [RoutePath.StudentLoanNonProfitStartDate]: 'Student Loan Non Profit Start Date',
  [RoutePath.StudentLoanDependentsQuantity]: 'Student Loan Dependents Quantity',
  [RoutePath.StudentLoanNotEnrolled]: 'Student Loan Not Enrolled',
  [RoutePath.StudentLoanPaymentSituations]: 'Student Loan Payment Situations',
  [RoutePath.StudentLoanSavings]: 'Student Loan Savings',
  [RoutePath.StudentLoanUploadAidData]: 'Student Loan Upload Aid Data',
  [RoutePath.StudentLoanEstimateUpdated]: 'Student Loan Estimate Updated',
  [RoutePath.StudentLoanBookAdvisor]: 'Student Loan Book Advisor',
  [RoutePath.StudentLoanNotEligible]: 'Student Loan Not Eligible',
  [RoutePath.StudentLoanScheduleDetails]: 'Student Loan Schedule Details',
  [RoutePath.StudentLoanApplyOptions]: 'Student Loan Apply Options',
  [RoutePath.StudentLoanApplyOnYourOwn]: 'Student Loan Apply on Your Own',
  [RoutePath.StudentLoanApplyWithUs]: 'Student Loan Apply with Us',
  [RoutePath.StudentLoanApplySubmit]: 'Student Loan Apply Submit',
  [RoutePath.StudentLoanApplyPaymentResult]: 'Student Loan Apply Payment Result',
  [RoutePath.StudentLoanApplyStepsIntro]: 'Student Loan Apply Steps Intro',
  [RoutePath.StudentLoanApplySteps]: 'Student Loan Apply Steps',
  [RoutePath.StudentLoanApplyStepsCompleted]: 'Student Loan Apply Steps Completed',
  [RoutePath.StudentLoanComeBackWhenDue]: 'Student Loan Come Back When Due',
  [RoutePath.StudentLoanNoSavings]: 'Student Loan No Savings',
  [RoutePath.StudentLoanServiceAgreementDone]: 'Student LoanService Agreement Done',
  // Debt Consolidation
  [RoutePath.OfferAvailable]: 'Offer Available',
  [RoutePath.ImportBalance]: 'Import Balance',
});
