import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg';
import { ReactComponent as EmailIcon } from 'images/email-icon.svg';
import { getApplicationData } from 'selectors/getApplicationData';
import { getLoanOfferData } from 'selectors/getLoanOfferData';
import OutOfState from 'components/OutOfState';
import BackToWebsite from 'components/BackToWebsite';
import { ConversionType, trackConversion } from 'utils/analytics';
import { OfferStatusResult } from 'enums/FlowNextResults';
import { FlowComponentType } from 'routes/types';
import ErrorConsolidating from 'components/DebtConsolidation/ErrorConsolidating/ErrorConsolidating';
import FormContainer from 'components/LoanForm/FormContainer';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';

import styles from './OfferStatus.module.scss';

const renderContent = (status: ApplicationStatusName | ApplicationStatusName) => {
  switch (status) {
    case ApplicationStatusName.Rejected: {
      return {
        title: 'That’s a bummer',
        subtitle: `It doesn't look like we can approve you for a loan.`, // TODO: Add "healthcare employee" in front of "loan"
      };
    }

    case ApplicationStatusName.AlreadyApplied: {
      return {
        title: 'That’s a bummer',
        subtitle: 'Looks like you have already applied.',
      };
    }

    case ApplicationStatusName.ManualVerify: {
      return {
        title: 'Thanks, we’re confirming your information!',
        subtitle: 'We’re verifying your information, and will contact you once your account is ready!',
      };
    }

    case ApplicationStatusName.FinalReview: {
      return {
        title: 'Final Review',
        subtitle: (
          <>
            <p>
              As a final step, we are reviewing that everything has been completed successfully. We will contact you
              when it’s time to sign the loan agreement.
            </p>
            <p>After signing, you will receive your funds within 3 business days.</p>
          </>
        ),
      };
    }

    default: {
      return {
        title: 'We are working on your offer',
        subtitle: 'As soon as we are ready we will contact you.',
      };
    }
  }
};

const isRejected = (status: ApplicationStatusName | ApplicationStatusName) => status === ApplicationStatusName.Rejected;

const isOutOfState = (status: ApplicationStatusName | ApplicationStatusName) =>
  status === ApplicationStatusName.OutOfState;

const OfferStatus = ({ handleNext, flags, navigationInfo }: FlowComponentType): JSX.Element => {
  const appData = useSelector(getApplicationData).application!;
  const offerResult = useSelector(getLoanOfferData).data!;

  const applicationStatus = appData?.status || offerResult?.application_status;

  const handleBackToFinances = () => {
    handleNext(OfferStatusResult.BackToYourFinances);
  };

  useEffect(() => {
    if (applicationStatus) {
      analytics.track(applicationStatus);
      if (applicationStatus === ApplicationStatusName.ManualVerify) {
        trackConversion(ConversionType.LoanManualVerify);
      }
    }
  }, []);

  if (isOutOfState(applicationStatus)) {
    return <OutOfState />;
  }
  if (isRejected(applicationStatus)) {
    return <ErrorConsolidating handleNext={handleNext} flags={flags} navigationInfo={navigationInfo} />;
  }

  const content = renderContent(applicationStatus);

  const allowBackToFinances = applicationStatus !== ApplicationStatusName.AlreadyApplied;

  return (
    <>
      <FormContainer
        title={content.title}
        subtitle={content.subtitle}
        icon={
          applicationStatus === ApplicationStatusName.ManualVerify ? (
            <CheckIcon className={styles.checkIcon} />
          ) : undefined
        }
      >
        <p className={styles.questionsTitle}>Questions?</p>
        <div className={styles.contactsContainer}>
          <a href="sms:(404) 205-8882" className={styles.contactItemContainer}>
            <ChatIcon />
            <p className={styles.contactsTitle}>Text or call us</p>
            <p className={styles.contactsSubtitle}>(404) 205-8882</p>
          </a>
          <a href="mailto:outofstate@planneryapp.com" className={styles.contactItemContainer}>
            <EmailIcon />
            <p className={styles.contactsTitle}>Email us</p>
            <p className={styles.contactsSubtitle}>Typically respond within 24-48 hours</p>
          </a>
        </div>
        {allowBackToFinances && (
          <div className={styles.whatsNextContainer}>
            <p className={styles.whatsNextTitle}>What's Next</p>
            <div className={styles.subtitle}>
              See how else Plannery can help, and keep track of your application status.
            </div>
            <Button className={styles.primaryButton} onClick={handleBackToFinances}>
              See Your Finances
            </Button>
          </div>
        )}
        {!allowBackToFinances && <BackToWebsite />}
      </FormContainer>
    </>
  );
};

export default OfferStatus;
