import React, { useState } from 'react';
import OtpInput from 'components/OtpInput';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import UserSessionWarning from 'components/Common/UserSessionWarning/UserSessionWarning';
import { AuthOtpResponseError, ErrorType } from 'api/AuthApi';
import { VerifyCodeResult } from 'enums/FlowNextResults';

import styles from './VerifyCode.module.scss';

export enum ContinueMode {
  Manual,
  OnEnterKey,
  OnCodeValid,
}

export interface Props {
  onResendCode(): Promise<void>;
  onVerify(code: string): Promise<boolean>;
  continueMode?: ContinueMode;
  consent?: JSX.Element;
  noAutoComplete?: boolean;
  handleSessionError?: (result: VerifyCodeResult) => void;
}

const VerifyCode = ({ onResendCode, onVerify, continueMode, consent, noAutoComplete, handleSessionError }: Props) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [codeIsValid, setCodeIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [failedAuth, setFailedAuth] = useState(false);

  function handleVerificationCodeChange(code: string) {
    const isValid = code.trim().length === 6;

    setCodeIsValid(isValid);
    setVerificationCode(code);

    if (isValid && continueMode === ContinueMode.OnCodeValid) {
      handleContinue(code);
    }
  }

  const handleContinue = async (code: string) => {
    if (isLoading) {
      return;
    }
    setFailedAuth(false);
    setIsLoading(true);
    try {
      const result = await onVerify(code);
      if (result) {
        return;
      }
    } catch (error) {
      const response = error as AuthOtpResponseError;

      if (response.errorType === ErrorType.DifferentEmailSession) {
        return handleSessionError && handleSessionError(VerifyCodeResult.DifferentEmailSession);
      }
      if (response.errorType === ErrorType.DifferentPhoneNumberSession) {
        return handleSessionError && handleSessionError(VerifyCodeResult.DifferentPhoneNumberSession);
      }
    }
    setIsLoading(false);
    setVerificationCode('');
    setFailedAuth(true);
    setCodeIsValid(false);
  };

  const handleResendCode = async () => {
    if (isLoading) {
      return false;
    }
    setIsLoading(true);
    await onResendCode();
    setIsLoading(false);
    setFailedAuth(false);
  };

  return (
    <>
      <OtpInput
        name="code"
        className={styles.formInput}
        onChange={(value) => handleVerificationCodeChange(value)}
        value={verificationCode}
        valueLength={6}
        disabled={isLoading}
        autoFocus
        noAutoComplete={noAutoComplete}
        onKeyUp={(e) =>
          e.key === 'Enter' &&
          codeIsValid &&
          continueMode === ContinueMode.OnEnterKey &&
          handleContinue(verificationCode)
        }
      />
      {failedAuth && <UserSessionWarning message="Invalid code, please check if you entered it correctly" />}

      {consent}

      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          disabled={isLoading || !codeIsValid}
          isLoading={isLoading}
          onClick={() => {
            handleContinue(verificationCode);
          }}
        >
          {consent ? 'Agree and Continue' : 'Continue'}
        </Button>

        <Button
          className={styles.button}
          disabled={isLoading}
          onClick={handleResendCode}
          isLoading={isLoading}
          type={ButtonType.Secondary}
        >
          Resend code
        </Button>
      </div>
    </>
  );
};

export default VerifyCode;
