import React from 'react';

import FormNavigation from 'components/FormNavigation';
import CopyTextToClipboard from 'components/CopyTextToClipboard';
import BackToWebsite from 'components/BackToWebsite';
import { FlowComponentType } from 'routes/types';

import { ReactComponent as RoadSign } from 'images/road-sign.svg';

import styles from './StayInTouch.module.scss';

const StayInTouch = ({ navigationInfo }: FlowComponentType) => (
  <div>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <div>
        <RoadSign />
        <div className={styles.title}>Thanks for checking out Plannery! </div>
      </div>
      <div className={styles.description}>We’d love to help you with your financial security in the future.</div>
      <p className={styles.keepInTouchText}>
        Keep in touch about future products.
        <br />
        Add <CopyTextToClipboard /> to your safe sender list.
      </p>
      <BackToWebsite />
    </div>
  </div>
);

export default StayInTouch;
