import { CardModelTextProps } from 'components/CardFlow/Customize/CardModel/CardModel';

export interface CardTitleOutput {
  primaryText: string;
  secondaryText: string;
}

const MAX_CARD_NAME_LENGTH = 19;

export const getCardTitle = ({
  borrowerFirstName,
  borrowerLastName,
  borrowerCredentialsText,
}: CardModelTextProps): CardTitleOutput => {
  const firstName = borrowerFirstName.trim();
  const lastName = borrowerLastName.trim();
  const cleanedBorrowerCredentials =
    borrowerCredentialsText && !borrowerCredentialsText.toLowerCase().includes('none')
      ? `, ${borrowerCredentialsText.trim()}`
      : '';

  const cardNameWithCredentials = `${firstName} ${lastName}${cleanedBorrowerCredentials}`;

  if (cardNameWithCredentials.length >= MAX_CARD_NAME_LENGTH) {
    return {
      primaryText: firstName,
      secondaryText: `${lastName}${cleanedBorrowerCredentials}`,
    };
  }

  return { primaryText: '', secondaryText: cardNameWithCredentials };
};
