import { RoutePath } from 'enums/Routes';
import YourFinances from 'components/FinancialCheckup/YourFinances';

import { NextFunction, RouterType } from './types';

export const getFinancialCheckupRoutes = (): RouterType => ({
  [RoutePath.YourFinances]: {
    navigationInfo: { showBackLink: false, title: 'Financial Checkup' },
    component: YourFinances,
    handleNext: () => () => {},
  },
});

export const backToYourFinances: NextFunction = ({ navigate }) => () => {
  navigate(RoutePath.YourFinances);
};
