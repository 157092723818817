import React, { FC } from 'react';
import clsx from 'clsx';
import BackLink from 'components/BackLink';
import Stepper from 'components/Card/Stepper/Stepper';
import useLayoutTheme from 'hooks/useLayoutTheme';
import useScreenSize from 'hooks/useScreenSize';
import { CurrentOnboardingSteps } from 'handlers/cardData';

import styles from './FormNavigation.module.scss';

export interface FormNavigationProps extends React.InputHTMLAttributes<HTMLInputElement> {
  showBackLink?: boolean;
  onBackClick?: () => void;
  title?: string;
  titleAsHeading?: boolean;
  step?: number;
  stepCount?: number;
  currentOnboardingStep?: CurrentOnboardingSteps;
}

const FormNavigation: FC<FormNavigationProps> = ({
  showBackLink,
  onBackClick,
  title,
  step,
  stepCount,
  className,
  currentOnboardingStep,
  titleAsHeading = false,
}) => {
  const { isMobile } = useScreenSize();
  const { themeStyles } = useLayoutTheme();

  return (
    <>
      <div className={clsx(styles.formNavigation, themeStyles.formNavigation, className)}>
        <div className={styles.holder}>
          {showBackLink !== false && <BackLink className={styles.backLink} onClick={onBackClick} />}
        </div>
        <div
          className={clsx(styles.title, {
            [styles.asHeading]: titleAsHeading,
          })}
        >
          {currentOnboardingStep !== undefined && !isMobile ? <Stepper currentStep={currentOnboardingStep} /> : title}
        </div>
        <div className={styles.steps}>{step && stepCount && `Step ${step} of ${stepCount}`}</div>
      </div>
      {currentOnboardingStep !== undefined && isMobile && <Stepper currentStep={currentOnboardingStep} />}
    </>
  );
};

export default FormNavigation;
