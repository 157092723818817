import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Input from 'components/Input';
import Button, { ButtonType } from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import PhoneNumberInput from 'components/PhoneNumberInput';
import FormContainer from 'components/LoanForm/FormContainer';

import { FlowComponentType } from 'routes/types';
import { AboutYouVariable, YourContactVariable } from 'enums/LoanFormVariables';
import { ConfirmYourDetailsResult } from 'enums/FlowNextResults';
import { getYourNameData } from 'selectors/yourName';
import { getYourContactData } from 'selectors/getYourContact';

import styles from './ConfirmYourDetails.module.scss';

export enum YourNameInputLabel {
  FirstName = 'First Name',
  LastName = 'Last Name',
  PhoneNumber = 'Phone Number',
  Email = 'Email',
}

export interface YourNameState {
  isSessionExpired?: boolean;
}

const ConfirmYourDetails = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const location = useLocation();
  const isSessionExpired = (location.state as YourNameState)?.isSessionExpired;

  const { phone_number: phoneNumber, email } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  const handleContinue = (updateFields = false) => {
    handleNext(updateFields ? ConfirmYourDetailsResult.UpdateFields : undefined);
  };

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Confirm Your Details"
        subtitle="This information will be submitted in your loan application."
      >
        {isSessionExpired && (
          <div className={styles.sessionExpired}>Sorry, your session is expired. Please reapply.</div>
        )}

        <div className={styles.inputsContainer}>
          <Input label={YourNameInputLabel.FirstName} name={AboutYouVariable.FirstName} value={firstName} disabled />
          <Input label={YourNameInputLabel.LastName} name={AboutYouVariable.LastName} value={lastName} disabled />
          <PhoneNumberInput
            label={YourNameInputLabel.PhoneNumber}
            name={YourContactVariable.PhoneNumber}
            onChange={() => {}}
            value={phoneNumber}
            country="US"
            disabled
          />
          <Input value={email} label={YourNameInputLabel.Email} name={YourContactVariable.Email} disabled />
        </div>

        <div className={styles.buttonsContainer}>
          <Button onClick={() => handleContinue()} className={styles.button}>
            This is correct
          </Button>
          <Button type={ButtonType.Inverted} onClick={() => handleContinue(true)} className={styles.button}>
            Make a change
          </Button>
        </div>
      </FormContainer>
    </div>
  );
};

export default ConfirmYourDetails;
