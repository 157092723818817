import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import SpendInBudgetModal from 'components/Card/SpendInBudgetModal';
import { FlowComponentType } from 'routes/types';
import { getCardData } from 'selectors/getCardData';
import { CurrentOnboardingSteps, setCurrentOnboardingStep } from 'handlers/cardData';

import { ReactComponent as MoneyIcon } from 'images/money-card-benefit-icon.svg';
import { ReactComponent as ShirtIcon } from 'images/redeem-shirt-icon.svg';
import { ReactComponent as PercentageIcon } from 'images/save-earn-percentage-up-icon.svg';

import styles from './CardRewards.module.scss';

interface RewardOptionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const rewardOptionsList: RewardOptionProps[] = [
  {
    icon: <MoneyIcon />,
    title: 'Cash Out',
    description: '100 points = $1',
  },
  {
    icon: <ShirtIcon />,
    title: 'Redeem',
    description: 'With scrubs, shoes, and coffee partners.',
  },
  {
    icon: <PercentageIcon />,
    title: 'Save & Earn',
    description: 'Your points automatically grow at a rate of 5% per year.',
  },
];

const CardRewards = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const { currentOnboardingStep } = useSelector(getCardData);
  const [isSpendInBudgetModalOpen, setIsSpendInBudgetModalOpen] = useState(false);

  useEffect(() => {
    if (currentOnboardingStep !== CurrentOnboardingSteps.Rewards)
      dispatch(setCurrentOnboardingStep(CurrentOnboardingSteps.Rewards));
  }, []);

  const handleNextStep = async () => {
    dispatch(setCurrentOnboardingStep(CurrentOnboardingSteps.SmartSpending));
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} currentOnboardingStep={currentOnboardingStep} />
      <FormContainer title="Earn Rewards for Healthy Spending">
        <div className={styles.pointsContainer}>
          <span className={styles.pointsTitle}>For every $1 spent, earn*:</span>
          <div className={styles.pointsRowContainer}>
            <div className={styles.pointsRow}>
              <div>
                <span>3</span> points
              </div>
              on nurse-related expenses
            </div>
            <div className={styles.pointsRow}>
              <div>
                <span>2</span> points
              </div>
              <p>
                when you{' '}
                <span onClick={() => setIsSpendInBudgetModalOpen(!isSpendInBudgetModalOpen)}>spend in budget</span> and
                repay your balance in full
              </p>
            </div>
          </div>
        </div>
        <div className={styles.pointsContainer}>
          <span className={styles.pointsTitle}>Use Points</span>
          <div className={styles.pointsListContainer}>
            {rewardOptionsList.map((option, index) => (
              <div className={styles.pointListCard} key={index}>
                <div className={styles.pointListCardIcon}>{option.icon}</div>
                <div>
                  <div className={styles.pointListTile}>{option.title}</div>
                  <div>{option.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Button type={ButtonType.Primary} onClick={handleNextStep}>
          Next
        </Button>
      </FormContainer>
      <span className={styles.rewardsDisclaimer}>* Rewards are expected and subject to change.</span>
      {isSpendInBudgetModalOpen && (
        <SpendInBudgetModal closeModal={() => setIsSpendInBudgetModalOpen(!isSpendInBudgetModalOpen)} />
      )}
    </>
  );
};

export default CardRewards;
