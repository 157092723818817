import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LOGOUT_ACTION_TYPE } from 'handlers';
import { UrlParams } from 'enums/UrlParams';
import useQueryParam from 'hooks/useQueryParam';

const Logout = () => {
  const dispatch = useDispatch();
  const flow = useQueryParam(UrlParams.Flow);
  const partner = useQueryParam(UrlParams.Partner);

  useEffect(() => {
    dispatch({
      type: LOGOUT_ACTION_TYPE,
    });
    if (flow) {
      const currentOrigin = window.location.origin;
      const url = new URL(currentOrigin);
      url.searchParams.append(UrlParams.Flow, flow);
      if (partner) {
        url.searchParams.append(UrlParams.Partner, partner);
      }
      window.location.href = url.toString();
    } else {
      window.location.href = 'https://www.planneryapp.com/';
    }
  }, []);

  return <div />;
};

export default Logout;
