import React from 'react';

import styles from './CardBenefitsList.module.scss';

export interface BenefitItemProps {
  icon: JSX.Element;
  title: string;
  description: JSX.Element | string;
}

interface CardBenefitsListProps {
  listBenefits: BenefitItemProps[];
}

const CardBenefitsList = ({ listBenefits }: CardBenefitsListProps) => (
  <div className={styles.benefitsContainer}>
    {listBenefits.map((benefit, index) => {
      const showLineSpacing = index + 1 < listBenefits.length;

      return (
        <React.Fragment key={index}>
          <div className={styles.benefitsCard}>
            <div className={styles.benefitCardIcon}>{benefit.icon}</div>
            <div>
              <div className={styles.benefitTile}>{benefit.title}</div>
              <div className={styles.benefitDescription}>{benefit.description}</div>
            </div>
          </div>
          {showLineSpacing && <div className={styles.lineSpacing} />}
        </React.Fragment>
      );
    })}
  </div>
);

export default CardBenefitsList;
