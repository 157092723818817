import React from 'react';
import clsx from 'clsx';

import styles from './BenefitsList.module.scss';

export interface BenefitItemProps {
  icon: JSX.Element;
  title: JSX.Element;
}

interface BenefitsListProps {
  benefits: BenefitItemProps[];
  className?: string;
}

const BenefitsList = ({ benefits, className }: BenefitsListProps) => (
  <div className={clsx(styles.benefitsContainer, className)}>
    {benefits.map((benefit, index) => (
      <div className={styles.benefitsCard} key={index}>
        <div className={styles.benefitsCardIcon}>{benefit.icon}</div>
        <div>{benefit.title}</div>
      </div>
    ))}
  </div>
);

export default BenefitsList;
