import React from 'react';

import BenefitsList, { BenefitItemProps } from 'components/Card/BenefitsList';

import { ReactComponent as MoneyIcon } from 'images/money-card-benefit-icon.svg';
import { ReactComponent as SmartMoneyMovesIcon } from 'images/smart-money-moves.svg';
import { ReactComponent as TrophyIcon } from 'images/trophy-card-benefit-icon.svg';
import { ReactComponent as PercentageIcon } from 'images/percentage-card-benefit-icon.svg';

import styles from './CardBenefits.module.scss';

const benefitsList: BenefitItemProps[] = [
  {
    icon: <TrophyIcon />,
    title: (
      <>
        <span>Earn up to $500</span> in rewards.
      </>
    ),
  },
  {
    icon: <SmartMoneyMovesIcon />,
    title: (
      <>
        <span>Spend less</span> and get AI coaching to keep you in budget.
      </>
    ),
  },
  {
    icon: <PercentageIcon />,
    title: (
      <>
        <span>Pay up to 90% less interest</span> compared to the average credit card.
      </>
    ),
  },
  {
    icon: <MoneyIcon />,
    title: (
      <>
        <span>Save up to $6,000</span> when you import your other balances.
      </>
    ),
  },
];

const CardBenefits = () => (
  <div className={styles.containerSavings}>
    <p>
      Total benefits: up to <span>$3,500</span> per year²
    </p>
    <div className={styles.spacingLine} />
    <BenefitsList className={styles.benefitsListContainer} benefits={benefitsList} />
  </div>
);

export default CardBenefits;
