import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';
import { Environments, getEnvironment } from 'utils/getEnvironment';
import { LOGOUT_ACTION_TYPE } from 'handlers';

const saveApplicationDataMiddleware = ({ getState }: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (
    action.type === LOGOUT_ACTION_TYPE ||
    (!action.type.startsWith('location') && (action.type.endsWith('fulfilled') || action.type.endsWith('rejected')))
  ) {
    if (getEnvironment() === Environments.Staging) {
      console.debug('Saving application data', action.type, getState());
    }

    localStorage.setItem(LocalStorageKeyName.ApplicationState, JSON.stringify(getState()));
  }

  return result;
};

export default saveApplicationDataMiddleware;
