import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AuthApi,
  AuthSessionData,
  AuthOtpData,
  SendEmailCodeData,
  SendSmsCodeData,
  SendSmsCodeForApplicationIdData,
} from 'api/AuthApi';

import { ErrorResponse } from './ErrorResponse';

export const createAuthSendSmsCodeThunk = (api: AuthApi) =>
  createAsyncThunk('auth/sendSmsCode', async (data: SendSmsCodeData, thunkApi) => {
    try {
      return await api.sendSmsCode(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response?.data);

      throw error;
    }
  });

export const createAuthSendSmsCodeForApplicationId = (api: AuthApi) =>
  createAsyncThunk('auth/sendSmsCodeForApplicationId', async (data: SendSmsCodeForApplicationIdData, thunkApi) => {
    try {
      return await api.sendSmsCodeForApplicationId(data);
    } catch (error) {
      thunkApi.rejectWithValue(error?.response?.data);
      throw error;
    }
  });

export const createAuthOtpThunk = (api: AuthApi) =>
  createAsyncThunk('auth/authOtp', async (data: AuthOtpData, thunkApi) => {
    try {
      return await api.authOtp(data);
    } catch (error: any) {
      return thunkApi.rejectWithValue({ status: error.responseStatus, ...error.data });
    }
  });

export const createAuthSendEmailCodeThunk = (api: AuthApi) =>
  createAsyncThunk('auth/sendEmailCode', async (data: SendEmailCodeData, thunkApi) => {
    try {
      return await api.sendEmailCode(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response?.data);

      throw error;
    }
  });

export const createAuthAuthSessionThunk = (api: AuthApi) =>
  createAsyncThunk('auth/authSession', async (data: AuthSessionData, thunkApi) => {
    try {
      return await api.authSession(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response?.data);
      throw error;
    }
  });
