import React from 'react';

import { copyToClipboard } from 'utils/copyToClipboard';
import { ReactComponent as CopyIcon } from 'images/copy-icon.svg';

import styles from './CopyTextToClipboard.module.scss';

export const supportEmail = 'support@planneryapp.com';

const CopyTextToClipboard = ({ text = supportEmail }) => (
  <span className={styles.container} onClick={() => copyToClipboard(text)}>
    {text} <CopyIcon className={styles.icon} />
  </span>
);

export default CopyTextToClipboard;
