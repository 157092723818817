import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { RootState } from 'handlers';
import { getCardTitle } from 'utils/getCardTitle';
import { getCardData } from 'selectors/getCardData';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';

import { ReactComponent as GoldCard } from 'images/cards/gold-card-front.svg';
import { ReactComponent as LightPurpleCard } from 'images/cards/light-purple-card-front.svg';
import { ReactComponent as PurpleSilverCard } from 'images/cards/purple-silver-card-front.svg';

import { getApplicationData } from 'selectors/getApplicationData';

import styles from './CardPreview.module.scss';

const cards = {
  [CardColor.Gold]: {
    card: <GoldCard />,
    cardNameStyle: styles.gold,
  },
  [CardColor.LightPurple]: {
    card: <LightPurpleCard />,
    cardNameStyle: styles.purple,
  },
  [CardColor.PurpleSilver]: {
    card: <PurpleSilverCard />,
    cardNameStyle: styles.silver,
  },
};

export enum CardSize {
  Small = 'Small',
  Large = 'Large',
}

interface CardPreviewProps {
  showDefaultCard?: boolean;
  size?: CardSize;
}

const CardPreview = ({ showDefaultCard = false, size = CardSize.Small }: CardPreviewProps) => {
  const { borrowerCredentials, cardColor: cardSelectedColor } = useSelector(getCardData);
  const { application } = useSelector(getApplicationData);
  const aboutYou = useSelector((state: RootState) => state.yourName);
  const borrowerFirstName = application?.borrowerFirstName ?? aboutYou.first_name;
  const borrowerLastName = application?.borrowerLastName ?? aboutYou.last_name;

  const defaultCardColor = CardColor.Gold;
  const cardColor = (cardSelectedColor || defaultCardColor) as keyof typeof cards;
  const { card, cardNameStyle } = cards[showDefaultCard ? defaultCardColor : cardColor];

  const { primaryText, secondaryText } = getCardTitle({
    borrowerFirstName,
    borrowerLastName,
    borrowerCredentialsText: borrowerCredentials,
  });

  return (
    <div className={clsx(styles.cardPreviewContainer, styles[`cardPreview${size}`])}>
      <div>{card}</div>
      {!showDefaultCard && (
        <div className={clsx(styles.cardNameContainer, cardNameStyle)}>
          {primaryText && <span className={styles.primaryText}>{primaryText}</span>}
          <span className={styles.secondaryText}>{secondaryText}</span>
        </div>
      )}
    </div>
  );
};

export default CardPreview;
