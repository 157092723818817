import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPhoneNumberInfo } from 'components/Footer/Footer';
import { RoutePath } from 'enums/Routes';

import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';

import styles from './UserSessionWarning.module.scss';

interface UserSessionWarningProps {
  message: string;
  showContactUsOption?: boolean;
}

const UserSessionWarning = ({ message, showContactUsOption = false }: UserSessionWarningProps) => {
  const location = useLocation();
  const { phoneNumber: supportPhoneNumber } = getPhoneNumberInfo(location.pathname as RoutePath);

  const openSmsApp = () => {
    const smsAppUrl = `sms://${supportPhoneNumber}`;
    window.open(smsAppUrl);
  };

  return (
    <div className={styles.containerAlert}>
      <WarningIcon />
      <div>
        {message}
        {showContactUsOption && (
          <>
            {' '}
            or{' '}
            <span className={styles.contactUs} onClick={openSmsApp}>
              contact us
            </span>{' '}
            for assistance
          </>
        )}
        .
      </div>
    </div>
  );
};

export default UserSessionWarning;
