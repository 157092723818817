export enum ProfessionGroup {
  Nurse = 'Nurse',
  Physician = 'Physician',
  Healthcare = 'Healthcare',
}

export const getSingularGroupTerm = (professionGroup: ProfessionGroup): string => {
  if (professionGroup === ProfessionGroup.Nurse) {
    return 'nurse';
  }
  if (professionGroup === ProfessionGroup.Physician) {
    return 'physician';
  }
  return 'clinician';
};

export const getPluralGroupTerm = (professionGroup: ProfessionGroup): string => {
  if (professionGroup === ProfessionGroup.Nurse) {
    return 'nurses';
  }
  if (professionGroup === ProfessionGroup.Physician) {
    return 'physicians';
  }
  return 'clinicians';
};

export const getLoanProductName = (professionGroup: ProfessionGroup): string => {
  if (professionGroup === ProfessionGroup.Nurse) {
    return 'Nurse Loan';
  }
  if (professionGroup === ProfessionGroup.Physician) {
    return 'Physician Loan';
  }
  return 'Healthcare Worker Loan';
};

export const getTaglineGroupName = (professionGroup: ProfessionGroup): string => {
  if (professionGroup === ProfessionGroup.Nurse) {
    return 'Nurses';
  }
  if (professionGroup === ProfessionGroup.Physician) {
    return 'Physicians';
  }
  return 'Healthcare Workers';
};

export const getFinancialCheckupWebsiteUrl = (professionGroup: ProfessionGroup): string => {
  if (professionGroup === ProfessionGroup.Nurse) {
    return 'https://www.planneryapp.com/platform/nurses';
  }
  if (professionGroup === ProfessionGroup.Physician) {
    return 'https://www.planneryapp.com/platform/physicians';
  }

  return 'https://www.planneryapp.com/platform/healthcare-professionals';
};
