import React from 'react';
import { useSelector } from 'react-redux';
import Button, { ButtonType } from 'components/Button';
import { getFinancialCheckupWebsiteUrl } from 'enums/ProfessionGroup';
import { getProfessionGroup } from 'selectors/professionGroup';

interface BackToWebsiteProps {
  text?: string;
  onClick?: () => void;
  className?: string;
}

const BackToWebsite = ({ text = 'Back to Plannery Website', onClick, className }: BackToWebsiteProps) => {
  const { professionGroup } = useSelector(getProfessionGroup);

  const handleGetBack = () => {
    window.open(getFinancialCheckupWebsiteUrl(professionGroup));
  };

  return (
    <Button className={className} type={ButtonType.Secondary} onClick={onClick || handleGetBack}>
      {text}
    </Button>
  );
};

export default BackToWebsite;
