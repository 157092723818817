import React from 'react';

import CardPreview, { CardSize } from 'components/Card/CardPreview';

import styles from './CardSummaryDetails.module.scss';

const CardSummaryDetails = () => (
  <div className={styles.cardContainer}>
    <CardPreview size={CardSize.Large} />
    <div className={styles.cardSummaryContainer}>
      <div>
        Total Credit
        <div>
          <span>$1,500-$15,000</span>¹
        </div>
      </div>
      <div>
        APR (if paid within 30 days)
        <span>0%</span>
      </div>
      <div>
        APR
        <span>12.99-23.99%</span>
      </div>
      <div>
        Membership fee
        <span>$7 biweekly</span>
      </div>
    </div>
  </div>
);

export default CardSummaryDetails;
