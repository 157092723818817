import React from 'react';

import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';

import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import CopyTextToClipboard from 'components/CopyTextToClipboard';
import BackToWebsite from 'components/BackToWebsite';

import { FlowComponentType } from 'routes/types';

import styles from './CardApplicationSuccessful.module.scss';

const CardApplicationSuccessful = ({ navigationInfo }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <FormContainer icon={<CheckIcon />} title="Thanks for joining the Plannery Card waitlist!">
      <p className={styles.subtitle}>We’d like to keep in touch about the Plannery Card launch.</p>
      <p className={styles.safeSenderMessage}>
        Add <CopyTextToClipboard /> to your safe sender list.
      </p>
      <BackToWebsite />
    </FormContainer>
  </>
);

export default CardApplicationSuccessful;
