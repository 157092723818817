import React from 'react';
import { BenefitItemProps } from 'components/Card/BenefitsList';

import { ReactComponent as MoneyIcon } from 'images/money-card-benefit-icon.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-card-benefit-icon.svg';
import { ReactComponent as SplitBalancesIcon } from 'images/split-balances-icon.svg';
import { ReactComponent as PercentageIcon } from 'images/percentage-card-benefit-icon.svg';

export const autoPayBenefitsList: BenefitItemProps[] = [
  {
    icon: <MoneyIcon />,
    title: (
      <>
        Get paid up to<span> 2 days earlier</span>¹.
      </>
    ),
  },
  {
    icon: <CalendarIcon />,
    title: (
      <>
        <span>Smart calendar</span> ensures you can cover other bills.
      </>
    ),
  },
  {
    icon: <SplitBalancesIcon />,
    title: (
      <>
        Split balances into 6 payments to <span>minimize interest.</span>
      </>
    ),
  },
  {
    icon: <PercentageIcon />,
    title: (
      <>
        <span>Reduce your interest rate by 1% </span>by making consistent payments.
      </>
    ),
  },
];
